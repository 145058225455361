export const environment = {
  production: true,
  graphQL_URL:'https://api.stoltuat.com/pooling-portal/graphql',
  navType: 'uat',
  B2C_CLIENTID:'f78e1a3e-67eb-4e09-aefd-3f23ba738f61',
  AUTHORITY:'https://stoltuatb2c.b2clogin.com/stoltuatb2c.onmicrosoft.com/B2C_1A_STDIGITAL_SIGNUP_SIGNIN',
  KNOWNAUTHORITIES:['stoltuatb2c.b2clogin.com'],
  USERSCOPE:'https://stoltuatb2c.onmicrosoft.com/3c70d490-3ae4-46b4-baed-09e47aeb65f0/read',
  GRAPH_ENDPONT:'https://graph.microsoft.com/v1.0/me',
  REDIRECT_URI:'https://stpoolingportal.stoltuat.com/dashboard',
  POST_LOGOUT_REDIRECT_URI:'https://stpoolingportal.stoltuat.com',
  googleAnalytic:'uat',
  googleAnalyticKey:'G-VLD0MB84G5',
  googleTagManager:'GTM-N3CS736',
  googleAnalyticUAKey:'UA-277407103-1',
  GET_IP_ADDRESS_API :"https://ipwhois.app/json/"
};



